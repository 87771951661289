/* eslint-disable react/no-array-index-key */
import React from 'react';

const breakLine = (text) => text.split(/\n|%%/).map((item, index) => (
  <React.Fragment key={index}>
    {item}
    <br />
  </React.Fragment>
));

export default breakLine;
