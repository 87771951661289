import React from 'react';
import { shape, string, array, bool } from 'prop-types';
import classNames from 'classnames';
import IconChevron from '../../../commons/icons/chevron';
import Section from '../../../commons/section';
import withTracker from '../../../commons/with-tracker';
import Card from './card';
import MobileCarousel from './mobile-carousel';

const LoyaltyPartnerSubscriptions = ({
  loyalty_partner_card_info = {
    type: null,
    title: null,
    elements: null,
    button: null,
  } }) => {
  if (!loyalty_partner_card_info) {
    return null;
  }

  const { title, type, button, elements, has_white_background } = loyalty_partner_card_info;

  if (!elements) {
    return null;
  }
  const bigCard = elements.length === 1;
  const shouldUseCarousel = elements.length === 3 || elements.length > 4;
  const namespace = 'partners-subscriptions-mobile';

  const className = classNames(`${namespace}__thumbs`, {
    [`${namespace}__thumbs--big`]: bigCard,
    [`${namespace}__thumbs--carousel`]: shouldUseCarousel,
  });

  return (
    <Section
      type={type}
      className={classNames(`${namespace}`, {
        [`${namespace}--white`]: has_white_background,
      })}
    >
      <div className="row container">
        <div className="partners-subscriptions-mobile__header">
          <span className="partners-subscriptions-mobile__header--text">
            {title}
          </span>
        </div>
        <div className={className}>
          {shouldUseCarousel ? (
            <MobileCarousel elements={elements} />
          ) : (
            elements.map((element) => (
              <Card key={element.target} {...element} bigCard={bigCard} />
            ))
          )}
        </div>
        {button && (
          <a
            href={button.link}
            className="partners-subscriptions-mobile__button"
          >
            <span className="partners-subscriptions-mobile__button--text">
              {button.label}
            </span>
            <IconChevron aria-hidden="true" />
          </a>
        )}
      </div>
    </Section>
  );
};

LoyaltyPartnerSubscriptions.propTypes = {
  loyalty_partner_card_info: shape({
    type: string,
    title: string,
    button: shape({
      label: string,
      link: string,
      image: string,
    }),
    elements: array,
    has_white_background: bool,
  }),
  deviceType: string,
};

export default withTracker(LoyaltyPartnerSubscriptions);
