import React from 'react';
import { shape, string, bool } from 'prop-types';
import { Pill } from '@andes/badge';
import classNames from 'classnames';
import Image from '../../../commons/image';
import { getIcon, makeGradientMobile } from './_utils';
import breakLine from '../../../../../utils/break-line';

const Card = ({
  target,
  picture,
  logo: { logo },
  title,
  subtitle,
  kicker,
  pill,
  card_pill,
  bigCard,
}) => {
  const pillStyles = {
    backgroundColor: (pill && pill.background_color) || '#00a650',
    color: (pill && pill.title && pill.title.color) || '#fff',
  };

  const namespace = 'partners-subscriptions-mobile';
  const classNameDetails = classNames(`${namespace}__thumbs__card-details`, {
    [`${namespace}__thumbs__card-details--big`]: bigCard,
    [`${namespace}__thumbs__card-details--withpill`]:
      bigCard && pill && pill.title,
  });

  const kickerStyle = {
    color: kicker && kicker.color ? kicker.color : '#FFF',
  };

  const maxLengthTitle = title && title?.label?.length >= 35;

  return (
    <a
      key={subtitle && subtitle.label ? subtitle.label : ''}
      href={target}
      className={`${namespace}__thumbs__card`}
    >
      <div className={`${namespace}__thumbs__card-container`}>
        <Image
          {...picture}
          className={`${namespace}__thumbs__card-background`}
          lazyLoad
          alt={picture.alt || subtitle.label}
        />
        {card_pill?.label && (
          <Pill
            hierarchy="loud"
            size="small"
            roundedCorners={['bottom-left']}
            secondaryColor={{
              background: card_pill.background_color,
              text: card_pill.color,
            }}
            className={`${namespace}__thumbs__card-card-pill`}
          >
            {card_pill.label}
          </Pill>
        )}
        <div className={classNameDetails}>
          <Image
            {...logo}
            size="48px"
            className={`${namespace}__thumbs__card-logo`}
            lazyLoad
            alt={logo.alt}
          />
          <div className={`${namespace}__thumbs__card-texts`}>
            {kicker && (
              <span
                className={`${namespace}__thumbs__card-texts--kicker`}
                style={kickerStyle}
              >
                {kicker.label}
              </span>
            )}

            {/* ? If length is more 35 characters need apply some styles to font */}
            {title && (
              <span
                className={`${namespace}__thumbs__card-texts--title ${
                  maxLengthTitle
                    ? `${namespace}__thumbs__card-texts--title__xl`
                    : ''
                }`}
              >
                {breakLine(title.label)}
              </span>
            )}

            {subtitle && (
              <span className={`${namespace}__thumbs__card-texts--subtitle`}>
                {subtitle.label}
              </span>
            )}
          </div>
        </div>
        <div
          className={`${namespace}__thumbs__card-gradient`}
          style={makeGradientMobile(picture.gradient_color)}
        />
        {pill && pill.title && (
          <div className={`${namespace}__thumbs__card-pill`} style={pillStyles}>
            {pill.image && (
              <div
                className={`${namespace}__thumbs__card-pill--icon`}
                aria-hidden="true"
              >
                {getIcon(pill.image)}
              </div>
            )}
            <span className={`${namespace}__thumbs__card-pill--text`}>
              {pill.title.label}
            </span>
          </div>
        )}
      </div>
    </a>
  );
};

Card.propTypes = {
  kicker: shape({
    label: string,
    color: string,
  }),
  title: shape({
    label: string.isRequired,
  }).isRequired,
  subtitle: shape({
    label: string.isRequired,
  }).isRequired,
  picture: shape({
    src: string.isRequired,
    src2x: string.isRequired,
    alt: string,
    gradient_color: string,
  }).isRequired,
  logo: shape({
    logo: shape({
      src: string.isRequired,
      src2x: string.isRequired,
      alt: string,
    }).isRequired,
  }).isRequired,
  pill: shape({
    title: shape({
      label: string.isRequired,
      color: string,
    }),
    image: string.isRequired,
    background_color: string,
  }),
  bigCard: bool,
  target: string.isRequired,
  card_pill: shape({
    label: string,
    color: string,
    background_color: string,
  }),
};

export default React.memo(Card);
