import React from 'react';
import { array } from 'prop-types';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';

import Card from './card';

const MobileCarousel = ({ elements }) => {
  const items = elements.map((element) => ({
    ...element,
    id: element.target,
  }));

  return (
    <CarouselFree>
      {items.map((item) => (
        <CarouselFreeSlide key={item.id}>
          <Card {...item} />
        </CarouselFreeSlide>
      ))}
    </CarouselFree>
  );
};

MobileCarousel.propTypes = {
  elements: array.isRequired,
};

export default MobileCarousel;
